<template>
  <FilterContainer
    :lang="$store.getters.language.lang"
    v-model="searchValue"
    :filter-default="filterDefault"
    @search-value="handleFilterByName($event)"
    @close="goBack"
    @focus="onFocus"
  >
    <template v-slot:content>
      <!-- group filter -->
      <div
        class="segment"
        v-if="groups.length && !searchValue.length && !showDatazero"
      >
        <div class="segment-content">
          <div
            class="heading-small margin-bottom"
            v-text="$translations['for-you'].themes"
          />
          <div class="group">
            <div
              :key="index"
              class="group-box section-container-white content-small"
              @click="goGroupEntries(item)"
              v-for="(item, index) in groups"
            >
              <span class="group-box_text" v-text="item.text" />
            </div>
          </div>
        </div>
      </div>

      <!-- loader -->
      <div class="page-segment margin-top" v-if="searchValue.length && loading">
        <div class="entries">
          <div v-for="index in 6" :key="index">
            <Skeleton
              class="margin-bottom-xs"
              width="100%"
              height="150px"
              borderRadius="8px"
            />
            <Skeleton
              class="margin-bottom-xs"
              width="100%"
              height="22px"
              borderRadius="16px"
            />
            <Skeleton
              class="margin-bottom-xs"
              width="60%"
              height="15px"
              borderRadius="16px"
            />
            <Skeleton
              class="margin-bottom-xs"
              width="30%"
              height="15px"
              borderRadius="16px"
            />
          </div>
        </div>
      </div>

      <!-- entries -->
      <div
        class="margin-top"
        v-if="searchValue.length && entries.length && !loading && !showDatazero"
      >
        <Carrousel
          :title="key"
          :entry-values="value"
          :key="index"
          v-for="(value, key, index) of entriesByCategory"
        />
      </div>

      <!-- datazero -->
      <div class="segment" v-if="showDatazero">
        <div class="segment-content">
          <DataZeroSimple
            :text="dataZeroText"
            :image="require('@/assets/icons/search/search-default.svg')"
          />
        </div>
      </div>
    </template>
  </FilterContainer>
</template>

<script>
import { FilterContainer, Skeleton } from '@seliaco/red-panda'
import DataZeroSimple from '@/components/data-zeros/DataZeroSimple'
import { mapGetters } from 'vuex'
import MediaContentMixin from '@/mixins/media-content-mixin'
import { ForYouEvent } from '@/types/events'
import Carrousel from '@/views/for-you/components/v2/Carrousel'

export default {
  name: 'ForYouSearch',
  components: {
    FilterContainer,
    DataZeroSimple,
    Skeleton,
    Carrousel
  },
  mixins: [MediaContentMixin],
  data () {
    return {
      searchValue: '',
      focus: false,
      filterDefault: {
        type: 'TITLE'
      }
    }
  },
  created () {
    if (this.$route.query.p) {
      this.searchValue = this.$route.query.p
      this.focus = true
      this.$store.dispatch('forYou/list', {
        params: {
          title: this.searchValue
        }
      })
    }

    if (!this.groups.length) {
      this.$store.dispatch('forYou/setup')
    }
  },
  methods: {
    goBack () {
      if (this.focus) {
        this.focus = false
        this.searchValue = ''
        this.$router.replace({
          path: this.$route.path,
          query: {
            back: this.$route.query.back
          }
        })
        this.$store.commit('forYou/SET_SEARCH', { search: [] })
      } else {
        if (this.$route.query.back) {
          this.$router.replace({
            path: this.$route.query.back
          })
        } else {
          this.$router.replace({
            name: 'ForYou'
          })
        }
      }
    },
    handleFilterByName (value) {
      this.searchValue = value

      if (!value || value.length === 0 || value.match(/^ *$/) !== null) {
        return
      }

      this.$router.replace({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          p: this.searchValue
        }
      })

      this.$store.dispatch('forYou/list', {
        params: {
          title: value
        }
      }).then(() => ForYouEvent.searchSuccessful({ value, totalCount: this.$store.getters['forYou/count'] }))
    },
    onFocus () {
      this.focus = true
    },
    goGroupEntries (item) {
      this.$router.push({
        name: 'ForYouGroup',
        params: { group: item.code },
        query: {
          back: this.$route.fullPath
        }
      })
      ForYouEvent.topicOpen({ ...item })
    }
  },
  computed: {
    ...mapGetters({
      groups: 'forYou/group',
      entries: 'forYou/search',
      loading: 'forYou/loading'
    }),
    dataZeroText () {
      if (this.searchValue?.length > 0) {
        return this.$translations.search['filter-by'].datazero.replaceAll(
          '{name}',
          this.searchValue
        )
      }

      return this.$translations['for-you'].datazero
    },
    displayConfig () {
      return (value) => {
        return {
          ...value,
          duration: `${this.category(
            value.category.code
          )} • ${this.durationFormat(value.duration)}`
        }
      }
    },
    showDatazero () {
      return (
        (!this.loading && !this.entries?.length && this.searchValue.length) ||
        (!this.searchValue.length && this.focus)
      )
    },
    entriesByCategory () {
      const groupByCategory = {}
      this.entries.forEach(o => {
        const category = o.category.text
        if (!groupByCategory[category]) {
          groupByCategory[category] = []
        }
        groupByCategory[category].push(o)
      })
      return groupByCategory
    }
  }
}
</script>

<style lang="sass" scoped>
.group
  display: grid
  gap: 8px
  grid-template-columns: 1fr 1fr
  &-box
    padding: 16px
    width: 100%
    text-align: center
    display: flex
    align-items: center
    justify-content: center
    height: 68px
    &_text
      display: -webkit-box
      -webkit-line-clamp: 2
      -webkit-box-orient: vertical
      max-height: 30px
      min-height: 15px
      overflow: hidden
</style>
